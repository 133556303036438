<template>
    <div class="check">
        <nav-bar>
            <div slot="center">员工考核</div>
        </nav-bar>
        <img src="~assets/image/staff/banner.png" alt="" class="banner">
        <div class="messageDiv">
          <img src="~assets/image/staff/status.png" alt="">
          <span>点评状态:</span>
          <div class="rightDiv">
            <select name="" v-model="state" >
              <option value="" selected disabled>请选择</option>
              <option value="0">待点评</option>
              <option value="1">待签核</option>
              <option value="2">已完成</option>
            </select>
          </div>
        </div>
         <div class="messageDiv">
          <img src="~assets/image/staff/name.png" alt="">
          <span>员工姓名:</span>
          <div class="rightDiv">
            <input type="text" class="inputNumClass" placeholder="请输入员工姓名" v-model="username">
          </div>
        </div>
         <div class="messageDiv">
          <img src="~assets/image/staff/num.png" alt="">
          <span>员工编号:</span>
          <div class="rightDiv">
            <input type="text" name="" placeholder="请输入员工编号" class="inputNumClass" v-model="account">
          </div>
        </div>
         <div class="messageDiv">
          <img src="~assets/image/staff/check.png" alt="" >
          <span>考核评分:</span>
          <div class="rightDiv" style="border:none;">
            <input type="text" placeholder="最低分" class="inputClass">
            <div class="lineSpan"></div>
            <input type="text" placeholder="最高分" class="inputClass">
          </div>
        </div>
        <button class="findBtn" @click="toFind()">立即查找</button>
    </div>
</template>
<script>
import  NavBar from 'components/navBar/NavBar.vue'
import {validRole} from '../../network/login'
export default {
  name: 'StaffCheck',
  components: {
    NavBar  
  },
  data(){
    return {
        state:'',
        username:'',
        account:'',
    }
  },
  methods:{
    toFind(){
          this.$toast.loading({
                message: '努力加载中...',
                forbidClick: false,
                duration:0
            });
        validRole().then((res)=>{
          this.$toast.clear();
            if(res.success){
                if(res.data){
                      this.$router.push({
                          name:'CheckList',
                          query:{
                            username:this.username,
                            account:this.account,
                            state:this.state
                          }
                        });
                }else{
                  this.$toast.fail('你没有查询权限');
                }
            }else{
               this.$toast.fail(res.msg);
            }
        })
      
     
    },

  }
}

</script>
<style scoped>
  .banner{
    margin: 100px 24px 0px 24px;
    width: 702px;
  }
  .messageDiv{
    display: flex;
    align-items: center;
    margin-left: 34px;
    margin-right: 25px;
    height: 114px;
  }
  .messageDiv img{
    width: 30px;
    margin-right: 30px;
  }
  .messageDiv span{
    font-size: 30px;
    font-family: PingFang;
    font-weight: bold;
    color: #999999;
  }
  .findBtn{
    width: 80%;
    margin: 70px 10%;
    background: linear-gradient(-90deg, #2998F8, #08A3FE);
    border-radius: 45px;
    border: none;
    font-size: 30px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 90px;
  }
  .inputClass{
    width: 160px;
    border: none;
    height: 100%;
    border-bottom: solid 1px #cccccc;
    outline: none;

  }
  .inputNumClass{
    width: 100%;
    border: none;
    height: 100%;
    outline: none;
  }
  .rightDiv{
    margin-left: 40px;
    border-bottom: 1px solid #CCCCCC;
    width: 455px;
    height: 100%;
  }
  .rightDiv select{
    height: 100%;
    border: none;
    outline: none;
    width: 100%;
  }
  .rightImg{
    width: 17px;
  }
  .lineSpan{
    margin-left: 20px;
    margin-right: 20px;
    width: 95px;
    height: 1px;
    display: inline-block;
    background-color: #999999;
  }
</style>